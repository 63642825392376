<template>
  <div id="quiz-background-final" />
</template>

<script>
export default {
  name: 'QuizBackgroundFinal',
}
</script>

<style lang="scss">
#quiz-background-final {
  position: absolute;
  left: 0;
  top: 0;
  height: 160vh;
  width: 100%;
  background-image: url('~@/assets/images/pages/background_quiz_003.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media (min-height: 640px) {
    height: 150vh;
  }
  @media (min-height: 670px) {
    height: 120vh;
  }
  @media (min-height: 815px) {
    height: 100vh;
  }
}
</style>
